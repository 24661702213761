import { Route } from '@tanstack/router'
import { useEffect } from 'react'

import { rootRoute } from './root'

export const gameDepositRoute = new Route({
  getParentRoute: () => rootRoute,
  path: '/game-deposit',
  component: () => {
    useEffect(() => {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSON.stringify({ type : "depositNeed" }))
      } else {
        window.parent.postMessage(JSON.stringify({ type : "depositNeed" }), '*')
      }
    }, [])

    return null
  },
})
